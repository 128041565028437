import * as React from "react";
import { HeadFC, graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const Thanks = () => {
  const { t } = useTranslation();

  const title = t("siteName");

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
      </Helmet>
      <Layout>
        <main className="pt-100px pb-163px md:pt-160px md:pb-230px">
          <div className="mx-auto w-325 md:w-785">
            <h1 className="mb-90px text-center font-condensed text-64px tracking-04 md:mb-40px md:text-128px">
              Thank you.
            </h1>
            <p className="mb-30px whitespace-pre-wrap text-16 font-medium leading-1.8 md:mb-120px md:text-center md:text-16">
              {t("thanks")}
            </p>
          </div>
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query Thanks($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Thanks;
